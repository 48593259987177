<template>
  <div class="min-h-screen antialiased font-sans bg-gray-100 overflow-hidden">
    <div v-if="loaded || hasError">
      <div class="max-w-3xl py-4 mx-auto sm:px-6">
        <img class="rounded bg-white object-scale-down w-full" src="@/assets/i.jpg" alt="" />
      </div>
      <div>
        <div class="max-w-3xl mx-auto sm:px-6">
          <div class="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" class="divide-y divide-gray-100">
              <li class="px-4 py-4 sm:px-6">

                <h2 v-if="hasError" class="text-3xl font-extrabold text-gray-900 sm:text-3xl">
                  指定されたフォームは見つかりませんでした
                  <!-- 現在メンテナンス中です。しばらくしてから再度お試しくださいませ。 -->
                </h2>
                <h2 v-else class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                  {{this.formData.title}}
                </h2>
                <p v-if="hasError" class="mt-3 max-w-3xl text-sm text-gray-600 whitespace-pre-wrap">
                  URL が間違っておらず、フォームが存在することをご確認ください。
                  <!-- メンテナンス予定時間：<br>
                  ※メンテナンス時間は延長される可能性がございます。 -->
                </p>
                <p v-else-if="page !== 'end'" class="mt-3 max-w-3xl text-sm text-gray-600 whitespace-pre-wrap">
                  {{this.formData.description}}
                </p>
                <div v-if="page === 'end'">
                  <p class="mt-3 max-w-3xl text-sm text-gray-600">
                    {{this.formData.endText}}
                  </p>
                  <div v-if="this.formData.endButtonLabel && this.formData.endButtonUrl" class="mt-3">
                    <a v-bind:href="this.formData.endButtonUrl" target="_blank" >
                      <button type="button" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {{this.formData.endButtonLabel}}
                      </button>
                    </a>
                  </div>
                </div>
              </li>

              <div v-if="hasError">
                <li class="px-4 py-4 sm:px-6">
                  <a href="https://startpass.jp/">
                    <button type="button" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      StartPass ウェブサイトに戻る
                    </button>
                  </a>
                </li>
              </div>
              <div v-else>
                <div v-if="page !== 'end'">

                  <li class="px-4 py-4 sm:px-6">
                    <div class="grid grid-cols-1 gap-y-8 gap-x-4 sm:grid-cols-6">

                      <!-- questions1 -->
                      <div v-for="(item, index) in questions1" :key="'questions1'+index" class="sm:col-span-6">
                        <div v-if="item['type'] == 'SECTION_HEADER'">
                          <h3 class="text-xl leading-6 font-bold text-gray-900 sm:col-span-6">
                            {{ item['title'] }}
                          </h3>
                          <p class="mt-3 max-w-3xl text-sm text-gray-600 whitespace-pre-wrap">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'TEXT'">
                          <div class="w-4/6">
                            <label for="url" class="block text-base font-medium text-gray-700">
                              {{ item['title'] }}
                            </label>
                            <div class="mt-1">
                              <input v-model="answer1[index]['value']"
                              name="url" type="url" autocomplete="date" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="回答を入力" />
                            </div>
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'PARAGRAPH_TEXT'">
                          <label for="about" class="block text-base font-medium text-gray-700">
                            {{ item['title'] }}
                          </label>
                          <div class="mt-1">
                            <textarea v-model="answer1[index]['value']"
                            rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" placeholder="回答を入力" />
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'MULTIPLE_CHOICE'">
                          <fieldset>
                            <div>
                              <legend class="text-base font-medium text-gray-700">
                                {{ item['title'] }}
                              </legend>
                            </div>
                            <div class="mt-4 space-y-4">
                              <div v-for="(c,i) in item['choice']" :key="'choice'+item['id']+i">
                                <div v-if="c === 'その他'" class="flex items-center">
                                  <input v-model="answer1[index]['value']"
                                  :name="'name'+item['id']" :id="'name'+item['id']+i" :value="c" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name'+item['id']+i" class="ml-3 flex items-center text-sm font-medium text-gray-700">
                                    <span class="w-16">その他 : </span>
                                    <input v-if="answer1[index]['value'] === 'その他'" v-model="answer1[index]['other']"
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" />
                                    <input v-else
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" disabled />
                                  </label>
                                </div>
                                <div v-else class="flex items-center">
                                  <input v-model="answer1[index]['value']"
                                  :name="'name'+item['id']" :id="'name'+item['id']+i" :value="c" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name'+item['id']+i" class="ml-3 block text-sm font-medium text-gray-700">
                                    {{ c }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div v-else-if="item['type'] == 'CHECKBOX'">
                          <fieldset>
                            <div>
                              <legend class="text-base font-medium text-gray-700">
                                {{ item['title'] }}
                              </legend>
                            </div>
                            <div class="mt-4 space-y-4">
                              <div v-for="(c,i) in item['choice']" :key="'choice_'+item['id']+'_'+i">
                                <div v-if="c === 'その他'" class="flex items-center">
                                  <input v-model="answer1[index]['selectedchoices']"
                                  type="checkbox" :id="'name_'+item['id']+'_'+i" :value="c" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name_'+item['id']+'_'+i" class="ml-3 flex items-center text-sm font-medium text-gray-700">
                                    <span class="w-16">その他 : </span>
                                    <input v-if="answer1[index]['selectedchoices'].includes('その他')" v-model="answer1[index]['other']"
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" />
                                    <input v-else
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" disabled />
                                  </label>
                                </div>
                                <div v-else class="flex items-center">
                                  <input v-model="answer1[index]['selectedchoices']"
                                  type="checkbox" :id="'name_'+item['id']+'_'+i" :value="c" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name_'+item['id']+'_'+i" class="ml-3 block text-sm font-medium text-gray-700">
                                    {{ c }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div v-else-if="item['type'] == 'DATE'">
                          <div class="w-4/6">
                            <label for="email" class="block text-base font-medium text-gray-700">
                              {{ item['title'] }}
                            </label>
                            <div class="mt-1">
                              <input v-model="answer1[index]['value']"
                              type="date" autocomplete="date" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                          </div>
                        </div>

                      </div>


                      <!-- questions2 -->
                      <div v-for="(item, index) in questions2" :key="'questions2'+index" class="sm:col-span-6">
                        <div v-if="item['type'] == 'SECTION_HEADER'">
                          <h3 class="text-xl leading-6 font-bold text-gray-900 sm:col-span-6">
                            {{ item['title'] }}
                          </h3>
                          <p class="mt-3 max-w-3xl text-sm text-gray-600 whitespace-pre-wrap">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'TEXT'">
                          <div class="w-4/6">
                            <label for="url" class="block text-base font-medium text-gray-700">
                              {{ item['title'] }}
                            </label>
                            <div class="mt-1">
                              <input v-model="answer2[index]['value']"
                              name="url" type="url" autocomplete="date" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="回答を入力" />
                            </div>
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'PARAGRAPH_TEXT'">
                          <label for="about" class="block text-base font-medium text-gray-700">
                            {{ item['title'] }}
                          </label>
                          <div class="mt-1">
                            <textarea v-model="answer2[index]['value']"
                            rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" placeholder="回答を入力" />
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'MULTIPLE_CHOICE'">
                          <fieldset>
                            <div>
                              <legend class="text-base font-medium text-gray-700">
                                {{ item['title'] }}
                              </legend>
                            </div>
                            <div class="mt-4 space-y-4">
                              <div v-for="(c,i) in item['choice']" :key="'choice'+item['id']+i">
                                <div v-if="c === 'その他'" class="flex items-center">
                                  <input v-model="answer2[index]['value']"
                                  :name="'name'+item['id']" :id="'name'+item['id']+i" :value="c" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name'+item['id']+i" class="ml-3 flex items-center text-sm font-medium text-gray-700">
                                    <span class="w-16">その他 : </span>
                                    <input v-if="answer2[index]['value'] === 'その他'" v-model="answer2[index]['other']"
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" />
                                    <input v-else
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" disabled />
                                  </label>
                                </div>
                                <div v-else class="flex items-center">
                                  <input v-model="answer2[index]['value']"
                                  :name="'name'+item['id']" :id="'name'+item['id']+i" :value="c" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name'+item['id']+i" class="ml-3 block text-sm font-medium text-gray-700">
                                    {{ c }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div v-else-if="item['type'] == 'CHECKBOX'">
                          <fieldset>
                            <div>
                              <legend class="text-base font-medium text-gray-700">
                                {{ item['title'] }}
                              </legend>
                            </div>
                            <div class="mt-4 space-y-4">
                              <div v-for="(c,i) in item['choice']" :key="'choice_'+item['id']+'_'+i">
                                <div v-if="c === 'その他'" class="flex items-center">
                                  <input v-model="answer2[index]['selectedchoices']"
                                  type="checkbox" :id="'name_'+item['id']+'_'+i" :value="c" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name_'+item['id']+'_'+i" class="ml-3 flex items-center text-sm font-medium text-gray-700">
                                    <span class="w-16">その他 : </span>
                                    <input v-if="answer2[index]['selectedchoices'].includes('その他')" v-model="answer2[index]['other']"
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" />
                                    <input v-else
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" disabled />
                                  </label>
                                </div>
                                <div v-else class="flex items-center">
                                  <input v-model="answer2[index]['selectedchoices']"
                                  type="checkbox" :id="'name_'+item['id']+'_'+i" :value="c" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name_'+item['id']+'_'+i" class="ml-3 block text-sm font-medium text-gray-700">
                                    {{ c }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div v-else-if="item['type'] == 'DATE'">
                          <div class="w-4/6">
                            <label for="email" class="block text-base font-medium text-gray-700">
                              {{ item['title'] }}
                            </label>
                            <div class="mt-1">
                              <input v-model="answer2[index]['value']"
                              type="date" autocomplete="date" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                          </div>
                        </div>

                      </div>


                      <!-- questions3 -->
                      <div v-for="(item, index) in questions3" :key="'questions3'+index" class="sm:col-span-6">
                        <div v-if="item['type'] == 'SECTION_HEADER'">
                          <h3 class="text-xl leading-6 font-bold text-gray-900 sm:col-span-6">
                            {{ item['title'] }}
                          </h3>
                          <p class="mt-3 max-w-3xl text-sm text-gray-600 whitespace-pre-wrap">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'TEXT'">
                          <div class="w-4/6">
                            <label for="url" class="block text-base font-medium text-gray-700">
                              {{ item['title'] }}
                            </label>
                            <div class="mt-1">
                              <input v-model="answer3[index]['value']"
                              name="url" type="url" autocomplete="date" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="回答を入力" />
                            </div>
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'PARAGRAPH_TEXT'">
                          <label for="about" class="block text-base font-medium text-gray-700">
                            {{ item['title'] }}
                          </label>
                          <div class="mt-1">
                            <textarea v-model="answer3[index]['value']"
                            rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" placeholder="回答を入力" />
                          </div>
                          <p class="mt-2 text-xs text-gray-500">
                            {{ item['helpText'] }}
                          </p>
                        </div>

                        <div v-else-if="item['type'] == 'MULTIPLE_CHOICE'">
                          <fieldset>
                            <div>
                              <legend class="text-base font-medium text-gray-700">
                                {{ item['title'] }}
                              </legend>
                            </div>
                            <div class="mt-4 space-y-4">
                              <div v-for="(c,i) in item['choice']" :key="'choice'+item['id']+i">
                                <div v-if="c === 'その他'" class="flex items-center">
                                  <input v-model="answer3[index]['value']"
                                  :name="'name'+item['id']" :id="'name'+item['id']+i" :value="c" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name'+item['id']+i" class="ml-3 flex items-center text-sm font-medium text-gray-700">
                                    <span class="w-16">その他 : </span>
                                    <input v-if="answer3[index]['value'] === 'その他'" v-model="answer3[index]['other']"
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" />
                                    <input v-else
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" disabled />
                                  </label>
                                </div>
                                <div v-else class="flex items-center">
                                  <input v-model="answer3[index]['value']"
                                  :name="'name'+item['id']" :id="'name'+item['id']+i" :value="c" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name'+item['id']+i" class="ml-3 block text-sm font-medium text-gray-700">
                                    {{ c }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div v-else-if="item['type'] == 'CHECKBOX'">
                          <fieldset>
                            <div>
                              <legend class="text-base font-medium text-gray-700">
                                {{ item['title'] }}
                              </legend>
                            </div>
                            <div class="mt-4 space-y-4">
                              <div v-for="(c,i) in item['choice']" :key="'choice_'+item['id']+'_'+i">
                                <div v-if="c === 'その他'" class="flex items-center">
                                  <input v-model="answer3[index]['selectedchoices']"
                                  type="checkbox" :id="'name_'+item['id']+'_'+i" :value="c" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name_'+item['id']+'_'+i" class="ml-3 flex items-center text-sm font-medium text-gray-700">
                                    <span class="w-16">その他 : </span>
                                    <input v-if="answer3[index]['selectedchoices'].includes('その他')" v-model="answer3[index]['other']"
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" />
                                    <input v-else
                                    type="text" class="-m-1 w-64 border-0 border-b border-transparent text-sm bg-gray-50 focus:border-indigo-600 focus:ring-0" placeholder="" disabled />
                                  </label>
                                </div>
                                <div v-else class="flex items-center">
                                  <input v-model="answer3[index]['selectedchoices']"
                                  type="checkbox" :id="'name_'+item['id']+'_'+i" :value="c" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                  <label :for="'name_'+item['id']+'_'+i" class="ml-3 block text-sm font-medium text-gray-700">
                                    {{ c }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div v-else-if="item['type'] == 'DATE'">
                          <div class="w-4/6">
                            <label for="email" class="block text-base font-medium text-gray-700">
                              {{ item['title'] }}
                            </label>
                            <div class="mt-1">
                              <input v-model="answer3[index]['value']"
                              type="date" autocomplete="date" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                          </div>
                        </div>

                      </div>


                    </div>
                  </li>
                  <div class="p-6">
                    <div class="flex justify-end">
                      <button @click="send" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        送信
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <p class="mt-8 text-center text-sm text-gray-400">
        Copyright &copy; StartPoint Co., Ltd. All rights reserved.
      </p>
      <p class="mt-2 mb-4 text-center text-xs text-blue-400">
        <a href="https://startpoint.jp/privacy-policy" target="_blank">
          プライバシーポリシー
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import Amplify, {API, Logger } from "aws-amplify";
Amplify.Logger.LOG_LEVEL = "ERROR";
const logger = new Logger("AmplifyFacebook");

export default {
  name: "Auth",
  components: {
    // Design3
  },
  data() {
    return {
      page: 1,
      user: null,
      formId: "",
      formData : {},
      loaded : false,
      accessed : false,
      hasError : false,
      questions1: [],
      answer1: [],
      questions2: [],
      answer2: [],
      questions3: [],
      answer3: [],
      api: {
        answer: {
          apiName: "answerApi",
          path: "/a"
        },
        form: {
          apiName: "formApi",
          path: "/f"
        },
      },
    };
  },
  computed: {
    isError() {
      return this.hasError === true;
    }
  },
  async created() {
    this.setFormIdForURL();
    const urlParams = new URLSearchParams(window.location.search);
    this.formId = urlParams.get("q");
    this.getForm();
  },
  methods: {
    send() {
      this.postAnswer(this.answer1.concat(this.answer2).concat(this.answer3));
      this.page = "end";
    },
    log(n) {
      if (n === "1") {
        logger.warn(this.answer1);
      }
      if (n === "2") {
        logger.warn(this.answer2);
      }
    },
    setFormIdForURL() {
      let formId = localStorage.getItem("formId");
      if (formId) {
        localStorage.removeItem("formId");
        const url = new URL(window.location);
        url.searchParams.set('q', formId);
        location.href = url;
      }
    },
    async postAnswer(data) {
      // CHECKBOXの場合、データを配列からカンマ区切りの文字に変換する
      data.forEach((answer, index) => {
        if (answer.type == "CHECKBOX" && Array.isArray(answer.selectedchoices)){
          if (answer.selectedchoices.includes('その他')) {
            answer.selectedchoices[answer.selectedchoices.indexOf('その他')] = "その他: " + answer.other;
          }
          data[index]["value"] = answer.selectedchoices.join(", ");
        }
      });

      const postData = {
          response: true,
          body: {
              id: this.formId + "/" + "none" + "/" + this.getCurrentLocalTimeInJapan(),
              fid: "none",
              title: this.formData.title,
              time: this.getCurrentLocalTimeInJapan(),
              formType: this.formData.formType,
              data: data
          }
      };
      API.put(this.api.answer.apiName, this.api.answer.path, postData).then(response => {
          logger.warn(response);
      }).catch(error => {
          logger.warn(error)
      });
    },
    async getInfoForm() {
      // フォーム側の検索ID指定
      const path = this.api.form.path + "/1ISFUMovntP4t8xV1vXXLWUV2GykxG22Xollh2O5aCbA";
      // オプション
      const req = {
        headers: {},
        response: true,
      };
      // フォーム側のデータ取得
      API.get(this.api.form.apiName, path, req).then(response => {
          // テーブル表示
          let data = JSON.parse(response.data.data);
          logger.warn("getInfoForm");
          Array.prototype.push.apply(this.questions2, data.questions);
          // 回答側にも初期値をセットしておく
          data.questions.forEach((question,index) => {
            logger.warn(question,index);
            this.answer2[index] = {id: question.id, title: question.title, type: question.type, value: "", other: "", selectedchoices: []};
          });
          logger.warn(this.answer2);
      }).catch(error => {
          // テーブルリセット
          logger.warn(error);
          this.hasError = true;
      });
    },
    async getInvestorForm() {
      // フォーム側の検索ID指定
      const path = this.api.form.path + "/1kiJbPCFnlRLrXU5bu94uxN3re3yoZH88O1vQLc4RhoY";
      // オプション
      const req = {
        headers: {},
        response: true,
      };
      // フォーム側のデータ取得
      API.get(this.api.form.apiName, path, req).then(response => {
          // テーブル表示
          let data = JSON.parse(response.data.data);
          logger.warn("getInvestorForm");
          Array.prototype.push.apply(this.questions3, data.questions);
          // 回答側にも初期値をセットしておく
          data.questions.forEach((question,index) => {
            logger.warn(question,index);
            this.answer3[index] = {id: question.id, title: question.title, type: question.type, value: "", other: "", selectedchoices: []};
          });
          logger.warn(this.answer3);
      }).catch(error => {
          // テーブルリセット
          logger.warn(error);
          this.hasError = true;
      });
    },
    async getForm() {
      // 検索ID指定
      const path = this.api.form.path + "/" + this.formId;
      // オプション
      const req = {
        headers: {},
        response: true,
      };
      // データ取得
      API.get(this.api.form.apiName, path, req).then(response => {
          // テーブル表示
          if (!response.data.data) {
            this.hasError = true;
          }
          let data = JSON.parse(response.data.data);
          logger.warn("getForm");
          this.formData = data;
          logger.warn(this.formData);
          Array.prototype.push.apply(this.questions1, data.questions);
          // 回答側にも初期値をセットしておく
          data.questions.forEach((question,index) => {
            logger.warn(question,index);
            this.answer1[index] = {id: question.id, title: question.title, type: question.type, value: "", other: "", selectedchoices: []};
          });
          logger.warn(this.answer1);
          this.loaded = true;
          document.title = "StartPass 申請フォーム | " + this.formData.title;

          // フォームタイプに応じてフォームのデフォルト設問(プロフィール設問/投資家向け設問)を制御
          if (this.formData.formType != "Other") {
            this.getInfoForm();
          }
          if (this.formData.formType == "Investor") {
            this.getInvestorForm();
          }
      }).catch(error => {
          // テーブルリセット
          logger.warn(error);
          this.hasError = true;
      });
    },
    getCurrentLocalTimeInJapan() {
      const nowLocal = new Date();
      const diff = nowLocal.getTimezoneOffset() * 60 * 1000;
      const plusLocal = new Date(nowLocal - diff);
      return plusLocal.toISOString().slice(0, 19) + '+09:00';
    }
  }
};
</script>
