/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "formApi",
            "endpoint": "https://oymwwad5c0.execute-api.ap-northeast-1.amazonaws.com/devtwo",
            "region": "ap-northeast-1"
        },
        {
            "name": "userAccessLogApi",
            "endpoint": "https://usehopjp59.execute-api.ap-northeast-1.amazonaws.com/devtwo",
            "region": "ap-northeast-1"
        },
        {
            "name": "answerApi",
            "endpoint": "https://y6367xhugc.execute-api.ap-northeast-1.amazonaws.com/devtwo",
            "region": "ap-northeast-1"
        },
        {
            "name": "userApi",
            "endpoint": "https://dku1aa6104.execute-api.ap-northeast-1.amazonaws.com/devtwo",
            "region": "ap-northeast-1"
        },
        {
            "name": "userInfoApi",
            "endpoint": "https://xwf1t0c7ck.execute-api.ap-northeast-1.amazonaws.com/devtwo",
            "region": "ap-northeast-1"
        },
        {
            "name": "investorRequestApi",
            "endpoint": "https://mame95avfe.execute-api.ap-northeast-1.amazonaws.com/devtwo",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:06e1128f-fcf0-46bd-838f-5479282bd8f1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_WyZlZu3tR",
    "aws_user_pools_web_client_id": "7r86mch3smj8nne0cert98mjit",
    "oauth": {
        "domain": "startpassfacebookuserpool.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:8080/,https://app.startpass.jp/,https://devtwo.d2pceexpbjgr3s.amplifyapp.com/",
        "redirectSignOut": "http://localhost:8080/,https://app.startpass.jp/,https://devtwo.d2pceexpbjgr3s.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "form-devtwo",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "userAccessLog-devtwo",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "answer-devtwo",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "user-devtwo",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "userInfo-devtwo",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "investorRequest-devtwo",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
