import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import axios from 'axios';

axios.interceptors.request.use((config) => {
  config.timeout = 10000; // 10sec
  return config;
}, (error) => {
  return Promise.reject(error);
});
// Fix issues with multiple redirect urls.
// Try to figure out which one to use...
const { host } = window.location;
if (aws_exports.oauth.redirectSignIn.includes(',')) {
  const filterHost = url => new URL(url).host === host;
  aws_exports.oauth.redirectSignIn = aws_exports.oauth.redirectSignIn
    .split(',')
    .filter(filterHost)
    .shift();
  aws_exports.oauth.redirectSignOut = aws_exports.oauth.redirectSignOut
    .split(',')
    .filter(filterHost)
    .shift();
}

Amplify.configure(aws_exports);
createApp(App).mount('#app')
